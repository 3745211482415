import CryptoJS from "crypto-js";

export default {
  encrypt(data) {
    let key = CryptoJS.enc.Utf8.parse("UE4YvRJXh9jW7TlV");
    let srcs = CryptoJS.enc.Utf8.parse(data);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  },
  decryptsfz(data) {
    let key = CryptoJS.enc.Utf8.parse("z09cqB7YURmn9B3k");
    let encrypted = CryptoJS.AES.decrypt(data, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString(CryptoJS.enc.Utf8);
  },
  encryptsfz(data) {
    let key = CryptoJS.enc.Utf8.parse("z09cqB7YURmn9B3k");
    let srcs = CryptoJS.enc.Utf8.parse(data);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  },
};
