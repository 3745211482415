import CryptoJS from "./CryptoJS";

const URL = process.env.VUE_APP_BASE_URL;

export default {
  genUrl(api, data={}) {
    let _data = CryptoJS.encrypt(
      "DpHxKkMbPymRYCFi" +
        new Date().getTime() +
        "," +
        (Math.floor(Math.random() * (9999 - 1000)) + 1000)
    );
    return (
      URL +
      api +
      "?" +
      this.convertObj(data) +
      "apikey=DpHxKkMbPymRYCFi&sign=" +
      encodeURIComponent(_data)
    );
  },
  convertObj(data) {
    const _result = [];
    if (Object.keys(data).length === 0) return '';

    for (let key in data) {
      let value = data[key];
      if (value.constructor === Array) {
        value.forEach(function (_value) {
          _result.push(key + "=" + _value);
        });
      } else {
        _result.push(key + "=" + encodeURIComponent(value));
      }
    }
    return _result.join("&") + '&';
  },
};
